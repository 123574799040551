'use client';

import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { BLOCKS } from '@contentful/rich-text-types';
import { type TooltipProps } from '@material-tailwind/react/components/Tooltip';
import { useSelector } from 'react-redux';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IContentfulImage, IRichTextData } from 'helpers/types';
import { ReplaceUrlWithHost } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';
import AlertIcon from 'public/icons/figma/alert.svg';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import CloseIconFigma from 'public/icons/figma/close-small.svg';
import { RootState } from 'redux/store';
import {
  DescriptionWithImage,
  HeadingWithIcon,
  TooltipAction,
  TooltipComponent,
  TooltipContainer,
  TooltipContent,
  TooltipOverlay,
} from './styles';
import { setTooltipArrowPosition, useTooltipScrollingPosition } from './tooltip-utils';

interface IContent {
  heading?: string;
  description;
  showReadMore?: boolean;
  image?: IContentfulImage;
  readMoreText?: string;
  readMoreLink?: string;
  hideCloseIcon?: boolean;
  closedColor?: string;
  openColor?: string;
  customContainerClass?: string;
  iconSize?: number;
}

export interface TProps extends Omit<TooltipProps, 'children'> {
  data?: IContent;
  handleOpen?: (index: number) => void;
  index?: number;
}

const Tooltip = ({ placement = 'bottom', open: openState, data, handleOpen, index, iconSize, ...rest }: TProps) => {
  const [open, setOpen] = useState(openState || false);
  const host = useSelector((state: RootState) => state.hostReducer.host);
  const [scrollPlacement, setScrollPlacement] = useState(placement);
  const [isTablet] = useMediaQuery(tablet);
  const tooltipIconRef = useRef(null);
  const tooltipContentRef = useRef(null);
  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  useTooltipScrollingPosition({ placement, tooltipIconRef, tooltipContentRef, scrollPlacement, setScrollPlacement });

  useEffect(() => {
    if (!isTablet && open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    if (open) {
      setTimeout(() => {
        setTooltipArrowPosition({
          tooltipIconRef,
          tooltipContentRef,
          placement,
          scrollPlacement,
          setScrollPlacement,
          isTablet,
        });
      }, 0);
    } else {
      // reset placement to default position when tooltip is closed
      setScrollPlacement(placement);
    }
    () => {
      document.body.style.overflow = 'auto';
    };
  }, [open, isTablet]);

  const {
    heading,
    description,
    image = '',
    showReadMore = false,
    readMoreText = '',
    hideCloseIcon = false,
    readMoreLink = '',
    closedColor = '[&_path]:stroke-grey-480',
    openColor = '[&_path]:stroke-leaf',
    customContainerClass = '',
  } = data || {};
  const replaceWithHost = ReplaceUrlWithHost(readMoreLink, host);
  const onClose = () => setOpen(false);

  return (
    <>
      {open && <TooltipOverlay $isHideCloseIcon={hideCloseIcon} style={{ top: window?.scrollY || 0 }} />}
      <TooltipContainer ref={tooltipIconRef}>
        <TooltipComponent
          data-testid="tooltip-container"
          open={open}
          placement={scrollPlacement}
          interactive={true}
          dismiss={{
            enabled: true,
            outsidePress: () => {
              setOpen(false);
              return false;
            },
            outsidePressEvent: 'click',
          }}
          className={`${
            heading && !image
              ? 'md:py-7'
              : heading && image
              ? 'md:pb-8 md:pt-6'
              : description && !heading && !image
              ? 'py-4'
              : ''
          } ${image ? 'md:w-[464px]' : 'md:w-[400px]'} md:px-8`}
          ref={tooltipContentRef}
          content={
            <TooltipContent>
              <HeadingWithIcon className={`${!heading ? 'justify-end' : ''}`}>
                {heading && (
                  <Typography
                    variant="body-small-bold"
                    className={`!text-grey-500 ${heading && showReadMore ? 'mb-3 lg:mb-4' : 'mb-3'}`}
                  >
                    {heading}
                  </Typography>
                )}
                {!hideCloseIcon && (
                  <CloseIconFigma
                    className={`h-6 w-6 shrink-0 cursor-pointer !text-grey-400`}
                    onClick={onClose}
                    data-testid="tooltip-container-close"
                  />
                )}
              </HeadingWithIcon>
              <DescriptionWithImage>
                {description?.json ? (
                  <ContentfulRichText
                    data={description as IRichTextData}
                    renderNodeOptions={{
                      [BLOCKS.PARAGRAPH]: (node, children) => (
                        <Typography variant="body-small" className="!text-grey-400" data-testid="tooltip-description">
                          {children}
                        </Typography>
                      ),
                    }}
                  />
                ) : (
                  (description as string)
                )}
                {image && (
                  <Image
                    className="rounded-md"
                    alt="tooltip-image"
                    loader={({ src }) => src}
                    src={image.url || ''}
                    width={image.width}
                    height={image.height}
                    title={image.title}
                  />
                )}
              </DescriptionWithImage>
              {showReadMore && (
                // TODO: Use IconWithTextButton
                <TooltipAction
                  target={replaceWithHost?.includes(host) ? '_self' : '_blank'}
                  className={`${heading && showReadMore ? 'mt-3 lg:mt-4' : 'mt-3'}`}
                  href={replaceWithHost || '/'}
                >
                  <Typography variant="body-small-bold" className="mr-1.5">{`${
                    readMoreText ? readMoreText : 'Read more'
                  }`}</Typography>
                  <ChevronRightIcon fontSize={'24px'} className="text-leaf " />
                </TooltipAction>
              )}
            </TooltipContent>
          }
          {...rest}
        >
          <div
            data-testid="tooltip-icon-box"
            onMouseEnter={() => {
              setOpen(true);
              if (handleOpen) handleOpen(index as number);
            }}
            onMouseLeave={() => {
              hideCloseIcon ? setOpen(false) : null;
            }}
            className={`inline-block ${customContainerClass}`}
          >
            <AlertIcon className={`${open ? openColor : closedColor}`} size={iconSize} />
          </div>
        </TooltipComponent>
      </TooltipContainer>
    </>
  );
};

export default Tooltip;
