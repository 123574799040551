import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import {
  ImageContainer,
  PromotionContainer,
  TextContainer,
} from 'components/molecules/tabs/dispensary-tabs/promotions-tab/styles';
import { ContentContainer } from 'components/molecules/tabs/dispensary-tabs/styles';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { ReplaceUrlWithHost, getPathMenuFiltersLink } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';
import { useAppSelector } from 'redux/hooks';

interface PromotionsTabProps {
  promotion: any;
}

export const PromotionsTab = ({ promotion }: PromotionsTabProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const host = useAppSelector((store) => store.hostReducer.host);
  if (!promotion) return <></>;
  const { expiryText, title, dealDescription, image, mobileImage, cta } = promotion;
  const selectedImage = isTablet ? image : mobileImage;
  const link = ReplaceUrlWithHost(cta.longUrl, host);

  return (
    <>
      <PromotionContainer>
        <ContentContainer className="!block gap-x-[42px] rounded-none px-5 text-center md:w-[858px] md:rounded-md md:px-12 md:py-0 xl:!flex xl:w-[1192px] xl:py-[56px]  min1440:px-[90px] min1440:py-[88px]">
          <TextContainer className="py-10">
            <div className="text-center md:text-left">
              <Typography variant="body-small" className="!text-grey-100" id="expiry_text">
                {expiryText}
              </Typography>
              <Typography variant="h4" as={'h3'} className="mb-6 mt-3 !text-white md:my-6" id="promotion_title">
                {title}
              </Typography>
              <div id="promotion_description_container">
                {dealDescription && (
                  <Typography variant="body-small" className="[&_div_p]:pb-0 [&_div_p]:!text-light">
                    <ContentfulRichText data={dealDescription} />
                  </Typography>
                )}
              </div>
              <CommonButton
                button-type="secondary"
                size="sm"
                color="white"
                className="mt-6 inline-block md:mt-[42px]"
                as="a"
                href={getPathMenuFiltersLink(link)}
              >
                Shop the discount
              </CommonButton>
            </div>
          </TextContainer>
          <div className="mb-10 inline-block">
            {selectedImage && (
              <ImageContainer>
                <Image
                  id="promotion_image"
                  loader={(options) => options.src}
                  alt={selectedImage.title}
                  title={selectedImage.title}
                  src={selectedImage.url}
                  objectFit="contain"
                  height={selectedImage.height}
                  width={selectedImage.width}
                />
              </ImageContainer>
            )}
          </div>
        </ContentContainer>
      </PromotionContainer>
    </>
  );
};
