import { HTMLAttributes } from 'react';
import { StyledTabItem, TabItemContainer, TabItemRightIcon } from '../styles';

export type TabVariant = 'large' | 'small' | 'small-subnav';

export interface TProps extends HTMLAttributes<HTMLHeadingElement> {
  text: string;
  variant: TabVariant;
  isActive?: boolean;
}

const TabItem = ({ text, variant, isActive = false, ...rest }: TProps) => {
  const StyledTabItemComponent = () => (
    <StyledTabItem $isActive={isActive} $variant={variant} {...rest}>
      {text}
    </StyledTabItem>
  );
  return variant === 'small' && isActive ? (
    <TabItemContainer>
      <TabItemRightIcon /> <StyledTabItemComponent />
    </TabItemContainer>
  ) : (
    <StyledTabItemComponent />
  );
};

export default TabItem;
