import { Tooltip, TooltipProps } from '@material-tailwind/react/components/Tooltip';
import tw from 'tailwind-styled-components';

export const tooltipArrowPosition = ({ placement }: TooltipProps) => {
  const mobileCenter = ` max640:!left-0 max640:!right-0 max640:!m-auto `;
  switch (placement) {
    case 'top':
      return ` after:left-[calc(50%_-_5px)] after:top-[calc(100%_-_5px)] after:rotate-[45deg]`;
    case 'right':
      return ` after:left-[calc(0%_-_5px)] after:top-[calc(50%_-_5px)] after:rotate-[135deg]` + mobileCenter;
    case 'bottom':
      return ` after:left-[calc(50%_-_5px)] after:top-[calc(0%_-_5px)] after:rotate-[225deg]`;
    case 'left':
      return ` after:left-[calc(100%_-_5px)] after:top-[calc(50%_-_5px)] after:-rotate-[45deg]` + mobileCenter;
    default:
      return ' after:hidden';
  }
};

export const TooltipComponent: React.FC<TooltipProps> = tw(Tooltip)`
  relative mx-auto w-[350px]
  rounded-md
  border
  border-solid
  border-grey-480
  [&_path]:stroke-grey-480
  bg-white
  px-5
  py-4
  shadow-2
  [&_img]:!min-w-16
  ${(p: TooltipProps) =>
    `
      after:absolute
      after:background-white
      after:border
      after:border-solid
      after:border-white
      after:border-[10px]
      after:border-r
      after:border-b
      after:border-r-grey-480
      after:border-b-grey-480
      after:content-['']
      ` + tooltipArrowPosition(p)}
`;

export const TooltipContent = tw.div`
  relative
`;

export const TooltipAction = tw.a`
  flex
  items-center
`;

export const HeadingWithIcon = tw.div`
  flex
  justify-between
  text-grey-500
`;

export const DescriptionWithImage = tw.div`
  flex
  justify-between
  items-center
  text-grey-400
`;

export const TooltipContainer = tw.div`
  h-6
  w-6
  [&_svg]:h-6
  [&_svg]:w-6
`;

export const TooltipOverlay = tw.div<{ $isHideCloseIcon: boolean }>`
  absolute
  left-0
  h-full
  w-full
  p-0
  m-0
  min-h-screen
  min-w-screen
  bg-overlay
  ${(p) => (p.$isHideCloseIcon ? '-z-[1]' : 'z-50')}
  md:hidden
`;
