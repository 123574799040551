import { ReactElement, useState } from 'react';
import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { DayListing } from 'components/atoms/listings/store-hours/day-listing';
import { Typography } from 'components/atoms/typography';
import {
  DeliveryContainer,
  HoursContainer,
  InfoDetailWrapper,
  InfoDetailsContainer,
  MapContainer,
  Spacer,
  TextContainer,
} from 'components/molecules/tabs/dispensary-tabs/delivery-tab/styles';
import { ContentContainer, HeadingContainer } from 'components/molecules/tabs/dispensary-tabs/styles';
import MapComponent from 'components/organisms/listings/map-listing/google-map';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { DayMap } from 'helpers/constants/misc';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IDispensaryTiming, MapType, TDetailForMap } from 'helpers/types';
import { tablet } from 'helpers/utils/screensizes';
import TimeIcon from 'public/icons/figma/time.svg';
interface DeliveryTabProps {
  timing: IDispensaryTiming[];
  data: Record<string, any>;
  geoJsons?: any[];
  host: string;
  geoLoc?: ReactElement;
  ListingDetailForMapArray: TDetailForMap[];
  openHourLabel?: string;
}

export const DeliveryTab = ({
  data,
  timing,
  geoJsons,
  host,
  ListingDetailForMapArray,
  geoLoc,
  openHourLabel = 'Opening hours',
}: DeliveryTabProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const storeHourstoDisplay = timing?.map((day: IDispensaryTiming) => {
    const hours = day?.hours;
    return {
      fromMedical: day?.fromMedical,
      toMedical: day?.toMedical,
      day: day?.day,
      dayToDisplay: DayMap[day?.day.toLowerCase()],
      hours,
    };
  });
  const desktopContainerStyle = {
    height: '205px',
    maxHeight: '810px',
  };
  const mobileContainerStyle = {
    height: '288px',
    width: '100%',
  };
  const [open, setOpen] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  if (!data) return <></>;

  return (
    <DeliveryContainer>
      <HeadingContainer>
        <div className="text-center md:text-left">
          {data.title && (
            <Typography variant="h4" as="h3" className="mb-6 !text-white">
              {data.title}
            </Typography>
          )}
          {data.description && (
            <Typography
              variant="body-small"
              className="!text-grey-200 [&_div_p]:pb-0 [&_p]:!text-grey-200"
              data-testid="description"
            >
              <ContentfulRichText data={data?.description} />
            </Typography>
          )}
        </div>
      </HeadingContainer>
      <ContentContainer className="block px-0" data-testid="mapContainer">
        <MapContainer>
          <MapComponent
            geoJsons={geoJsons}
            host={host}
            data={ListingDetailForMapArray}
            mapStyle={isTablet ? desktopContainerStyle : mobileContainerStyle}
            selectedListingFromParent={undefined}
            handleSetSelectedListingFromParent={undefined}
            geoLoc={geoLoc}
            center={undefined}
            open={open}
            setOpen={setOpen}
            page={MapType.dispensary}
            showLegend={false}
          />
        </MapContainer>
        <TextContainer $display={detailsOpen ? 'block' : 'flex'}>
          {detailsOpen || isTablet ? (
            <>
              <HoursContainer data-testid="hoursContainer">
                {storeHourstoDisplay?.length !== 0 && (
                  <>
                    <div className="mb-3 flex">
                      <TimeIcon className="mr-4 !text-grey-100" />
                      <Typography variant="body-small" className="!text-grey-100" data-testid="openingHoursText">
                        {openHourLabel}
                      </Typography>
                    </div>
                    <div className="ml-9" data-testid="hoursToDisplayContainer">
                      {storeHourstoDisplay.map((timing, index) => {
                        return (
                          <DayListing
                            key={index}
                            timing={timing}
                            shouldHighlight={false}
                            isLast={index === storeHourstoDisplay.length}
                            color={'!text-grey-100'}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </HoursContainer>
              <Spacer />
              <InfoDetailsContainer data-testid="infoDetailsContainer">
                {data?.listItemsCollection?.items &&
                  data?.listItemsCollection?.items?.slice(0, 4).map((item, index) => {
                    return (
                      <InfoDetailWrapper key={index} $borderTop={isTablet && index !== 0}>
                        <div className="!min-h-[24px] !min-w-[24px]">
                          <Image
                            className="!min-h-[24px] !min-w-[24px]"
                            loader={(item) => item.src}
                            src={item.logo?.url}
                            alt={item.logo?.title}
                            width={24}
                            height={24}
                          />
                        </div>
                        <Typography variant="body-small" className="text-nowrap ml-3 h-[16px] text-white">
                          {item.title}
                        </Typography>
                      </InfoDetailWrapper>
                    );
                  })}
              </InfoDetailsContainer>
            </>
          ) : null}
          <CommonButton
            data-testid="viewHideDetailsButton"
            className="mx-auto block md:hidden"
            button-type="secondary"
            color="white"
            size="sm"
            onClick={() => setDetailsOpen(!detailsOpen)}
          >{` ${detailsOpen ? 'Hide' : 'View'} delivery details`}</CommonButton>
        </TextContainer>
      </ContentContainer>
    </DeliveryContainer>
  );
};
