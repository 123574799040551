import { isForPopUpUrlToReplace } from 'helpers/constants/misc';
import { MenuSourceType } from 'helpers/types';
import { getDispensaryAddress } from 'helpers/utils/convert-to-dispensary-page-type';

export const getOpeningTime = (data, includeMedical = false) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const result = [];
  data?.forEach((item) => {
    item?.day?.forEach((day) => {
      const dayWiseTime = {};
      (dayWiseTime['day'] = day.substring(0, 3)),
        (dayWiseTime['hours'] = item?.customLabel
          ? item?.customLabel
          : `${item?.from} - ${item?.to}
  ${
    item?.fromMedical && item?.toMedical && includeMedical
      ? `Medical Hours:
  ${item?.fromMedical} - ${item?.toMedical}`
      : ''
  }`);
      dayWiseTime['fromMedical'] = item?.fromMedical?.replace(':00 AM', '');
      dayWiseTime['toMedical'] = item?.toMedical?.replace(':00 AM', '');
      const position = days.findIndex((d) => d.toLowerCase().includes(day.toLowerCase()));

      // @ts-ignore
      result[position] = dayWiseTime;
    });
  });
  const currentDay = new Date().getDay();
  const sorted = currentDay === 1 ? result : [...result.slice(currentDay - 1), ...result.slice(0, currentDay - 1)];
  return sorted;
};

export const convertToDispensaryType = (data) => {
  if (data && Array.isArray(data) && data.length > 0) {
    return data.map((item) => {
      const {
        title,
        latitude,
        longitude,
        retailBrand,
        hoursInformationCollection,
        mapUrl,
        contactNumber,
        description,
      } = item?.dispensaryInformation || {};
      return {
        title: title,
        lat: latitude,
        lng: longitude,
        icon: retailBrand?.logo?.url,
        uri: item?.slug,
        openingTime: getOpeningTime(hoursInformationCollection.items),
        address: getDispensaryAddress(item?.dispensaryInformation, item?.state),
        mapUrl: mapUrl,
        phoneNumber: contactNumber,
        description: description,
        heroImage: item.heroImage,
        mobileHeroImage: item.mobileHeroImage,
        menuBtns: item?.menus?.items.map((menuItem) => {
          return {
            name: menuItem?.title,
            url:
              menuItem?.menuSource === MenuSourceType.external || menuItem?.menuSource === MenuSourceType.gti
                ? menuItem?.menuSourceUrl
                : menuItem?.linkedFrom?.pageMenuCollection?.items[0]?.slug,
            isExternalLink:
              menuItem?.menuSource === MenuSourceType.external &&
              !menuItem?.menuSourceUrl?.includes(isForPopUpUrlToReplace),
            isDefault: menuItem.isDefault,
          };
        }),
      };
    });
  } else {
    return null;
  }
};
