import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { IEventCalendarModalData } from 'helpers/types';
import CommonModal from '../common-modal';
import { AngleLeft, BackLink, HeaderDiv, HeaderTitle, PopupBack, PopupBodyContent, ReservationIframe } from './styles';

interface TProps {
  eventCalendarModalData: IEventCalendarModalData;
  handleClose(): void;
  handleBackClick(e: any): void;
}
const EventsReservationModal = ({ eventCalendarModalData, handleBackClick, handleClose }: TProps) => {
  const { popupHeading, popupDescription, popupIFrameUrl } = eventCalendarModalData || {};
  const divID = 'events_reservation_modal';

  return (
    <CommonModal
      ariaHidden={true}
      reservationModal={true}
      noPadding={true}
      handleClose={handleClose}
      divID={divID}
      customModalStyle={'!top-[38px] md:!w-[575px]'}
      closeButtonColor="text-white"
    >
      <HeaderDiv>
        <HeaderTitle role="heading">{popupHeading}</HeaderTitle>
      </HeaderDiv>
      <PopupBack>
        <BackLink onClick={handleBackClick} id="back_button">
          <AngleLeft /> Back
        </BackLink>
      </PopupBack>
      <PopupBodyContent id="body_content">
        {popupDescription && <ContentfulRichText data={popupDescription} />}
      </PopupBodyContent>
      <ReservationIframe title="Seven Rooms Events" id="reservation" src={popupIFrameUrl} scrolling="auto" />
    </CommonModal>
  );
};

export default EventsReservationModal;
