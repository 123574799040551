import { PopUpType } from 'helpers/types';

export const getDispensaryAddress = (dispensaryInfromation, state) => {
  const { unitNumber, streetNumber, streetName, city, zipCode } = dispensaryInfromation || {};
  return `${unitNumber || ''}${unitNumber && streetName ? ' ' : ''}${streetName || ''}${
    streetNumber && streetName ? ' ' : ''
  }${streetNumber || ''}${streetName && city ? ', ' : ''}${city || ''}${city && state?.state?.stateCode ? ', ' : ''}${
    state?.state?.stateCode || ''
  } ${zipCode || ''}`;
};

export const getDispensaryAddressAlgolia = (dispensaryInfromation) => {
  const { street_two, street_one, city, zip, state_code } = dispensaryInfromation || {};
  return (
    `${street_two || ''}${street_two && street_one ? ' ' : ''}${street_one || ''}${street_one && city ? ', ' : ''}${
      city || ''
    }${city && state_code ? ', ' : ''}${state_code || ''} ${zip || ''}` || ''
  );
};
export const getMundeleinLoungeMenuBtns = (popupCtasCollection) => {
  return popupCtasCollection?.items
    ?.map((item) => {
      if (item?.popupType !== PopUpType.SMOKEY) {
        return {
          text: item?.buttonText,
          popUpType: item?.popupType,
        };
      }
    })
    .filter((x) => x);
};
export const getMundeleinMenuBtns = (popupCta) => {
  return popupCta
    ?.map((item) => {
      if (item?.popupType !== PopUpType.SMOKEY) {
        return {
          text: item?.buttonText,
          popUpType: item?.popupType,
        };
      }
    })
    .filter((x) => x);
};

type PopUpEventType = {
  title: string;
  description: string;
  iframeUrl?: string;
  image?: any;
  btns?: any;
};

export const getMundeleinLoungeModalsData = (popupCtasCollection) => {
  let reservationModalData: PopUpEventType;
  let eventCalendarModalData: PopUpEventType;
  let smokeEasyModalData: PopUpEventType;

  popupCtasCollection?.items?.forEach((item) => {
    const { popupHeading, popupDescription, popupImage, popupIFrameUrl, popupType } = item || {};

    switch (popupType) {
      case PopUpType.RESERVATION:
        {
          reservationModalData = {
            title: popupHeading,
            description: popupDescription,
            iframeUrl: popupIFrameUrl,
          };
        }
        break;
      case PopUpType.EVENT_LIST:
        {
          eventCalendarModalData = {
            title: popupHeading,
            description: popupDescription,
            iframeUrl: popupIFrameUrl,
          };
        }
        break;
      case PopUpType.SMOKEY:
        {
          smokeEasyModalData = {
            title: popupHeading,
            description: popupDescription,
            image: popupImage,
            btns: getMundeleinLoungeMenuBtns(popupCtasCollection),
          };
        }
        break;
      default:
        break;
    }
  });

  // @ts-ignore
  return { reservationModalData, eventCalendarModalData, smokeEasyModalData };
};

export const getMundeleinModalsData = (popupCta) => {
  let reservationModalData: PopUpEventType;
  let eventCalendarModalData: PopUpEventType;
  let smokeEasyModalData: PopUpEventType;

  popupCta?.forEach((item) => {
    const { popupHeading, popupDescription, popupImage, popupIFrameUrl, popupType } = item || {};

    switch (popupType) {
      case PopUpType.RESERVATION:
        {
          reservationModalData = {
            title: popupHeading,
            description: popupDescription,
            iframeUrl: popupIFrameUrl,
          };
        }
        break;
      case PopUpType.EVENT_LIST:
        {
          eventCalendarModalData = {
            title: popupHeading,
            description: popupDescription,
            iframeUrl: popupIFrameUrl,
          };
        }
        break;
      case PopUpType.SMOKEY:
        {
          smokeEasyModalData = {
            title: popupHeading,
            description: popupDescription,
            image: popupImage,
            btns: getMundeleinMenuBtns(popupCta),
          };
        }
        break;
      default:
        break;
    }
  });

  //@ts-ignore
  return { reservationModalData, eventCalendarModalData, smokeEasyModalData };
};
