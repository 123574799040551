import { Fragment } from 'react';
import { DayContainer } from 'components/atoms/listings/store-hours/styles';
import { Typography } from 'components/atoms/typography';
import { IDispensaryTiming } from 'helpers/types';
interface DayListingProps {
  timing: IDispensaryTiming;
  shouldHighlight?: boolean;
  isLast?: boolean;
  marginTiming?: boolean;
  color?: string;
}
export const DayListing = ({
  timing,
  shouldHighlight = false,
  isLast = false,
  marginTiming = true,
  color = '!text-grey-400',
}: DayListingProps) => {
  return (
    <DayContainer $bottomMargin={isLast ? '' : 'mb-4'}>
      <Typography
        variant={shouldHighlight ? 'body-small-bold' : 'body-small'}
        className={`
                          ${shouldHighlight ? '!text-grey-500' : color}
                          ${marginTiming ? 'w-[90px]' : ''}
                      `}
      >
        {timing.dayToDisplay}
        {marginTiming ? '' : <Fragment>&nbsp;</Fragment>}
      </Typography>
      <Typography
        variant={shouldHighlight ? 'body-small-bold' : 'body-small'}
        className={`
                                          ${shouldHighlight ? '!text-grey-500' : color}
                                          ${marginTiming ? 'ml-4' : ''}
                                      `}
      >
        {timing.hours}
      </Typography>
    </DayContainer>
  );
};
