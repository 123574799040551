import tw from 'tailwind-styled-components';

export const DispensaryTabsContainer = tw.div`
    pl-0
    md:pl-[50px]
    min1440:pl-[120px]
    pr-0
    md:pr-[60px]
    pt-[60px]
    md:py-[70px]
    relative
    block
    md:flex
    justify-center
    gap-x-0
    min1440:gap-x-[63px]
    md:gap-x-[20px]
    max-w-[1920px]
    mx-auto
`;

export const ContentContainer = tw.div`
    my-0
    md:my-[-130px]
    xl:max-h-[560px]
    max-w-[1192px]
`;
