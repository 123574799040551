'use client';

import { useState } from 'react';
import { Typography } from 'components/atoms/typography';
import AmenityPaymentTab from 'components/molecules/tabs/dispensary-tabs/amenity-payment-tab';
import { DeliveryTab } from 'components/molecules/tabs/dispensary-tabs/delivery-tab';
import { LoungeTab } from 'components/molecules/tabs/dispensary-tabs/lounge-tab';
import { PromotionsTab } from 'components/molecules/tabs/dispensary-tabs/promotions-tab';
import { TextTabs } from 'components/molecules/tabs/text-tabs';
import { ContentContainer, DispensaryTabsContainer } from 'components/organisms/listings/dispensary-tabs/styles';
import { bannerTrack } from 'helpers/analytics/banner-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import { IDispensaryTiming } from 'helpers/types';
import { getOpeningTime } from 'helpers/utils/convert-to-dispensary-type';
import { getMainDeliveryZone } from 'helpers/utils/delivery-zone';
enum TabName {
  payment = 'PAYMENT',
  amenities = 'AMENITIES',
  delivery = 'DELIVERY',
  lounge = 'SMOKEASY & LOUNGE',
  promotion = 'PROMOTION',
}
interface DispensaryTabsProps {
  data: Record<string, any>;
  host: string;
  tasticPlacementNumber: number;
}
export const DispensaryTabs = ({ data, host, tasticPlacementNumber }: DispensaryTabsProps) => {
  const tabs = data?.dispensaryTabsCollection?.items?.map((tab) => {
    if ((tab.tabTitle === 'Payment' || tab.tabTitle === 'Amenities') && tab?.listItemsCollection?.items?.length === 0) {
      return null;
    }
    return tab.tabTitle ? tab.tabTitle.toUpperCase() : null;
  });
  const [activeTab, setActiveTab] = useState(tabs?.[0]);
  const targetRef = useIntersectionObserver(() => {
    const bannerClickedData = {
      placement: tasticPlacementNumber + 1,
      banner_position: 1,
      banner_internal_name: data.visitingSectionTitle,
      banner_title: data.visitingSectionTitle,
      banner_image_main: '',
      banner_type: 'Other',
      link_name: '',
      link_type: '',
    };
    bannerTrack(SegmentEvents.BANNER_VIEWED, bannerClickedData);
  });
  if (tabs.length === 0) return <></>;
  const tabChange = (tab) => {
    setActiveTab(tab);
  };
  const hubGeojson = {};
  getMainDeliveryZone(data.deliveryZones?.items, hubGeojson);
  const { latitude, longitude } = data.dispensaryInformation || {};
  const detail = {
    title: '',
    lat: latitude,
    lng: longitude,
    icon: '',
    uri: '',
    openingTime: null,
    address: '',
    mapUrl: '',
    phoneNumber: null,
    description: null,
    heroImage: null,
    mobileHeroImage: null,
    menuBtns: [],
  };
  const deliveryTab = data?.dispensaryTabsCollection?.items?.find((tab) => tab.tabTitle === 'Delivery');
  const openHourLabel = deliveryTab?.tabTitle === 'Delivery' ? 'Delivery Hours' : undefined;
  return (
    <div className="bg-grey-100">
      <div className="mx-auto my-0 max-w-[2200px] md:my-[90px]">
        <DispensaryTabsContainer ref={targetRef}>
          <div>
            <Typography
              variant="h4"
              as={'h2'}
              className="mx-auto mb-[44px] max-w-[334px] text-center md:mx-0 md:mb-[56px] md:text-left"
            >
              {data.visitingSectionTitle}
            </Typography>
            <TextTabs tabs={tabs} onChange={tabChange} />
          </div>
          <ContentContainer id="content_container">
            {activeTab === TabName.amenities && (
              <AmenityPaymentTab
                data={data?.dispensaryTabsCollection?.items?.find(
                  (tab) => tab.tabTitle.trim().toLowerCase() === TabName.amenities.trim().toLowerCase(),
                )}
              />
            )}
            {activeTab === TabName.delivery && (
              <DeliveryTab
                // @ts-ignore
                ListingDetailForMapArray={[detail]}
                geoJsons={Object.values(hubGeojson)}
                host={host}
                openHourLabel={openHourLabel}
                timing={getOpeningTime(deliveryTab.deliveryTimeCollection?.items) as IDispensaryTiming[]}
                data={deliveryTab}
              />
            )}
            {activeTab === TabName.payment && (
              <AmenityPaymentTab
                data={data?.dispensaryTabsCollection?.items?.find(
                  (tab) => tab.tabTitle?.trim()?.toLowerCase() === TabName.payment?.trim()?.toLowerCase(),
                )}
              />
            )}
            {activeTab === TabName.lounge && (
              <LoungeTab
                data={data?.dispensaryTabsCollection?.items?.find(
                  (tab) => tab.tabTitle?.trim()?.toLowerCase() === TabName.lounge?.trim()?.toLowerCase(),
                )}
              />
            )}
            {activeTab === TabName.promotion && (
              <PromotionsTab
                promotion={
                  data?.dispensaryTabsCollection?.items?.find(
                    (tab) => tab.tabTitle?.trim()?.toLowerCase() === TabName.promotion?.trim()?.toLowerCase(),
                  ).listItemsCollection?.items?.[0] || null
                }
              />
            )}
          </ContentContainer>
        </DispensaryTabsContainer>
      </div>
    </div>
  );
};
