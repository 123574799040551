import tw from 'tailwind-styled-components';
import ChevronDownIcon from 'public/icons/figma/chevron-down.svg';
import ChevronUpIcon from 'public/icons/figma/chevron-up.svg';

export const StoreHoursContainer = tw.div`
    sm:mb-0
    relative
`;

export const HoursContainer = tw.div`
    pl-9
    mb-6
    md:mb-[10px]
    xl:mb-4
`;
interface DayContainerProps {
  $bottomMargin?: string;
}

export const DayContainer = tw.div<DayContainerProps>`
    flex
    ${(p) => p.$bottomMargin}
`;

export const CaretDownIcon = tw(ChevronDownIcon)`
  text-leaf
  sm:text-[20px]
  md:text-[27px]
`;

export const CaretUpIcon = tw(ChevronUpIcon)`
  text-leaf
  sm:text-[20px]
  md:text-[27px]
`;

export const DropdownButton = tw.button`
    ml-[6px]
`;

export const TopHoursContainer = tw.div`
    flex
    items-center
    mb-6
    md:mb-[10px]
    xl:mb-4
`;

export const HasMedicalContainer = tw.div`
  bg-white
  p-5
  rounded-md
  flex
  align-start
  gap-5
  mb-6
  md:mb-[10px]
  xl:mb-4
`;
