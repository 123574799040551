import { useState } from 'react';
import Image from 'next/image';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { ISmokeEasyModalData, PopUpType } from 'helpers/types';
import CommonModal from '../common-modal';
import {
  AngleRight,
  BodyContent,
  ButtonTitle,
  EventButton,
  EventDetailsContent,
  EventImage,
  EventParagraph,
  HeaderDiv,
  HeaderTitle,
  LoungeImageContainer,
} from './styles';

interface TProps {
  smokeEasyData: ISmokeEasyModalData;
  handleButtonClick(e: any, popUpType: PopUpType): void;
  handleClose(): void;
}
const SmokeasyModal = ({ smokeEasyData, handleButtonClick, handleClose }: TProps) => {
  const { popupHeading, popupDescription, buttonImage, btns } = smokeEasyData || {};
  const divID = 'reservation_modal';

  const [imageSize, setImageSize] = useState({
    width: 1,
    height: 1,
  });
  return (
    <>
      <CommonModal
        ariaHidden
        reservationModal={true}
        noPadding={true}
        customModalStyle={'!top-[50%] md:!w-[400px] translate-y-[-50%]'}
        handleClose={handleClose}
        closeButtonColor="text-white"
        divID={divID}
      >
        <HeaderDiv>
          <HeaderTitle role="heading">{popupHeading}</HeaderTitle>
        </HeaderDiv>
        <BodyContent>
          <EventImage>
            <LoungeImageContainer>
              <Image
                id="lounge_image"
                src={buttonImage?.url || ''}
                alt={buttonImage?.title || ''}
                layout="responsive"
                objectFit="contain"
                onLoadingComplete={(target) => {
                  setImageSize({
                    width: target.naturalWidth,
                    height: target.naturalHeight,
                  });
                }}
                width={imageSize.width}
                height={imageSize.height}
                loading="lazy"
                placeholder="empty"
                loader={(options) => options.src}
              />
            </LoungeImageContainer>
            <EventDetailsContent />
            <EventParagraph id="body_content">
              {popupDescription && <ContentfulRichText data={popupDescription} />}
            </EventParagraph>
            {btns?.map((btn) => (
              <EventButton
                key={btn?.text}
                id={btn?.text.toLowerCase().split(' ').join('_')}
                aria-controls={`reveal_overlay_${btn.text}`}
                aria-haspopup="true"
                tabIndex={0}
                onClick={(e) => handleButtonClick(e, btn?.popUpType as PopUpType)}
              >
                <ButtonTitle>{btn.text}</ButtonTitle>
                <AngleRight />
              </EventButton>
            ))}
          </EventImage>
        </BodyContent>
      </CommonModal>
    </>
  );
};

export default SmokeasyModal;
