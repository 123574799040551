import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { IReservationModalData } from 'helpers/types';
import CommonModal from '../common-modal';
import { AngleLeft, BackLink, HeaderDiv, HeaderTitle, PopupBack, PopupBodyContent, ReservationIframe } from './styles';

interface TProps {
  reservationModalData: IReservationModalData;
  handleClose(): void;
  handleBackClick(e: any): void;
}
const MakeReservationModal = ({ reservationModalData, handleClose, handleBackClick }: TProps) => {
  const { popupHeading, popupDescription, popupIFrameUrl } = reservationModalData || {};
  const divID = 'make_reservation_modal';
  return (
    <CommonModal
      ariaHidden={true}
      reservationModal={true}
      noPadding={true}
      handleClose={handleClose}
      divID={divID}
      customModalStyle={'!top-[50px] md:!w-[575px]'}
      closeButtonColor="text-white"
    >
      <HeaderDiv>
        <HeaderTitle role="heading">{popupHeading}</HeaderTitle>
      </HeaderDiv>
      <PopupBack>
        <BackLink onClick={(e) => handleBackClick(e)} id="back_button">
          <AngleLeft /> Back
        </BackLink>
      </PopupBack>
      <PopupBodyContent id="body_content">
        {popupDescription && <ContentfulRichText data={popupDescription} />}
      </PopupBodyContent>
      <ReservationIframe title="Seven Rooms Reservations" id="reservation" src={popupIFrameUrl} scrolling="auto" />
    </CommonModal>
  );
};

export default MakeReservationModal;
