import tw from 'tailwind-styled-components';

export const HeadingContainer = tw.div`
    bg-leaf-dark
    md:py-[60px]
    py-[42px]
    px-5
    md:pl-[90px]
    md:pr-[48px]
    grid
    items-center
    rounded-none
    xl:max-w-[350px]
    md:rounded-t-md
    xl:rounded-t-none
    xl:!rounded-l-md
    min1440:max-w-[437px]
`;

export const ContentContainer = tw.div`
    grid
    grid-cols-2
    bg-leaf
    rounded-none
    md:rounded-r-none
    md:!rounded-b-md
    xl:!rounded-b-none
    xl:!rounded-r-md
    px-12
    xl:w-[755px]
`;
