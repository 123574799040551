import tw from 'tailwind-styled-components';
import CloseIcon from 'public/icons/figma/close-small.svg';

interface RevealOverlayProps {
  $overlayStyle: string;
}

export const RevealOverlay = tw.div<RevealOverlayProps>`
      bg-grey-700
      z-[9999]
      overflow-y-auto
      fixed
      inset-0
      ${(p) => p.$overlayStyle}
  `;

export const Reveal = tw.div`
      md:w-[600px]
      max-w
      min-h-0
      z-[1006]
      border-[1px]
      border-solid
      border-[#cacaca]
      relative
      md:top-[100px]
      mx-auto
      overflow-y-auto
      inset-x-auto
      my-0
      mx-auto
      h-auto
      sm:w-full
      sm:top-0
  `;
interface ModalProps {
  $reservationModal?: boolean;
}
interface CloseButtonProps extends ModalProps {
  $color?: string;
}
export const ModalCloseButton = tw(CloseIcon)<CloseButtonProps>`
      absolute
      cursor-pointer
      mb-0
      top-1
      right-[.5rem]
      text-[2em]
      leading-none
      font-thin
      ${(p) => (p.$color ? p.$color : 'text-gray600')}
  `;

interface RevealDivProps extends ModalProps {
  $noPadding?: boolean;
  $noBorder?: boolean;
  $reservationModal?: boolean;
  $customStyle?: string;
}

export const RevealDiv = tw(Reveal)<RevealDivProps>`
      bg-grey-800
      ${(props) => (props.$noPadding ? '!p-0' : 'md:p-10 sm:p-2.5')}
      ${(p) => (p.$noBorder ? '!border-0' : '')}
      ${(p) => (p.$reservationModal ? 'border-none min-h-[572px]' : 'sm:p-2.5 md:p-10')}
      ${(p) => p.$customStyle}
      top-[88px]
      sm:w-full
  `;
