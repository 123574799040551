import React, { useState } from 'react';
import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import Tooltip from 'components/atoms/overlay/tooltip/tooltip';
import { Typography } from 'components/atoms/typography';
import {
  AmenityPaymentContainer,
  ContentContainer,
  ImageIconClasses,
  Spacer,
  TooltipContainer,
} from 'components/molecules/tabs/dispensary-tabs/amenity-payment-tab/styles';
import { HeadingContainer } from 'components/molecules/tabs/dispensary-tabs/styles';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';

interface AmenityPaymentTabProps {
  data: Record<string, any>;
}
const AmenityPaymentTab = ({ data }: AmenityPaymentTabProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const [amenitiesList, setAmenitiesList] = useState(4);

  if (!data) return <></>;

  const parsed = data?.listItemsCollection?.items;
  const { col1, col2 } = parsed?.reduce(
    (obj, data, index) => {
      index % 2 === 0 ? obj?.col1?.push(data) : obj?.col2?.push(data);
      return obj;
    },
    { col1: [], col2: [] },
  );

  const listing = isTablet ? parsed : parsed?.slice(0, amenitiesList) || [];

  const showMore = () => {
    if (parsed.length - listing.length > 6) {
      setAmenitiesList(amenitiesList + 6);
    } else if (parsed.length === amenitiesList) {
      setAmenitiesList(4);
    } else {
      setAmenitiesList(parsed?.length);
    }
  };

  return (
    <AmenityPaymentContainer>
      <HeadingContainer className="h-full md:h-[152px] md:px-12 md:py-10 xl:h-[559px] xl:px-[66px]">
        <div className="text-center md:text-left">
          {data?.title && (
            <Typography variant="h4" as="h3" className="mb-6 !text-white" data-testid="title">
              {data?.title}
            </Typography>
          )}
          {data?.description && (
            <Typography
              variant="body-small"
              className="!text-grey-200 [&_div_p]:pb-0 [&_p]:!text-grey-200"
              data-testid="description"
            >
              <ContentfulRichText data={data?.description} />
            </Typography>
          )}
        </div>
      </HeadingContainer>
      <ContentContainer data-testid="content">
        <div className="grid gap-y-[24px] md:hidden">
          {listing &&
            listing?.length > 0 &&
            listing?.map((item, index) => {
              if (!item.title || !item.logo) return <></>;
              return (
                <AmenityPaymentContainer key={index} className="flex max-h-full" data-testid="Amenitypayment1">
                  <div className={ImageIconClasses}>
                    <Image
                      className={ImageIconClasses}
                      loader={(item) => item.src}
                      src={item.logo?.url}
                      alt={item.logo?.title}
                      width={48}
                      height={48}
                    />
                  </div>
                  <Typography variant="body-small" className="ml-6 h-[16px] text-white">
                    {item.title}
                  </Typography>
                  {item.description && item.description !== '' && (
                    <Tooltip
                      open={false}
                      data={{
                        heading: isTablet ? '' : item.title,
                        description: item.description,
                        hideCloseIcon: isTablet ? true : false,
                        closedColor: '[&_path]:stroke-kief',
                        openColor: '[&_path]:stroke-kief',
                        customContainerClass: '!flex items-center ml-2',
                      }}
                    />
                  )}
                </AmenityPaymentContainer>
              );
            })}
        </div>
        {data?.listItemsCollection?.items?.length > 4 && (
          <CommonButton
            onClick={showMore}
            className="mb-[42px] mt-[32px]  md:hidden"
            button-type="secondary"
            color="white"
            size="sm"
            data-testid="showMore"
          >
            {data?.listItemsCollection?.items?.length - listing?.length > 6
              ? ' Show more (6)'
              : listing.length === data?.listItemsCollection?.items?.length
              ? 'Show less'
              : ` Show more (${data?.listItemsCollection?.items?.length - listing?.length})`}
          </CommonButton>
        )}
        <>
          <div className="hidden gap-y-6 md:flex md:flex-col" data-testid="col1">
            {col1 &&
              col1?.length > 0 &&
              col1?.map((item, index) => {
                return (
                  <AmenityPaymentContainer
                    key={index}
                    className="flex max-h-full max-w-[233px]"
                    data-testid="Amenitypayment1"
                  >
                    <div className={ImageIconClasses}>
                      <Image
                        className={ImageIconClasses}
                        loader={(item) => item.src}
                        src={item.logo?.url}
                        alt={item.logo?.title}
                        width={48}
                        height={48}
                      />
                    </div>
                    <Typography variant="body-small" className="ml-6 h-[16px] text-white">
                      {item?.title}
                    </Typography>
                    {item?.description && item?.description !== '' && (
                      <TooltipContainer>
                        <Tooltip
                          open={false}
                          data={{
                            heading: isTablet ? '' : item?.title,
                            description: item.description,
                            hideCloseIcon: isTablet ? true : false,
                            closedColor: '[&_path]:stroke-kief',
                            openColor: '[&_path]:stroke-kief',
                            customContainerClass: '!flex items-center',
                          }}
                        />
                      </TooltipContainer>
                    )}
                  </AmenityPaymentContainer>
                );
              })}
          </div>
          <Spacer />
          <div className="hidden gap-y-6 md:flex md:flex-col" data-testid="col2">
            {col2 &&
              col2.length > 0 &&
              col2.map((item, index) => {
                return (
                  <AmenityPaymentContainer
                    key={index}
                    className="flex max-h-full max-w-[233px]"
                    data-testid="Amenitypayment1"
                  >
                    <div className={ImageIconClasses}>
                      <Image
                        className={ImageIconClasses}
                        loader={(item) => item.src}
                        src={item.logo?.url}
                        alt={item.logo?.title}
                        width={48}
                        height={48}
                      />
                    </div>
                    <Typography variant="body-small" className="ml-6 h-[16px] text-white">
                      {item.title}
                    </Typography>
                    {item.description && item.description !== '' && (
                      <TooltipContainer>
                        <Tooltip
                          open={false}
                          data={{
                            heading: isTablet ? '' : item.title,
                            description: item.description,
                            hideCloseIcon: isTablet ? true : false,
                            closedColor: '[&_path]:stroke-kief',
                            openColor: '[&_path]:stroke-kief',
                            customContainerClass: '!flex items-center',
                          }}
                        />
                      </TooltipContainer>
                    )}
                  </AmenityPaymentContainer>
                );
              })}
          </div>
        </>
      </ContentContainer>
    </AmenityPaymentContainer>
  );
};

export default React.memo(AmenityPaymentTab);
