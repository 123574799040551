import { useRef, useState } from 'react';
import Slider from 'react-slick';
import TabItem from 'components/atoms/navigation/tab-item/tab-item';
import { TabContainer } from 'components/molecules/tabs/text-tabs/styles';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

interface TextTabsProps {
  tabs: string[];
  onChange: (tabName) => void;
}

export const TextTabs = ({ tabs, onChange }: TextTabsProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const [isActive, setIsActive] = useState(0);
  const tabClick = (index, tab) => {
    setIsActive(index);
    onChange(tab);
    !isTablet && slickRef.current?.slickGoTo(index);
  };

  const tabItems = tabs.map((tab, index) => {
    return (
      <TabItem
        key={index}
        className={` mx-6 ${index !== tabs.length - 1 ? 'mb-3' : ''}`}
        variant="small"
        text={tab}
        isActive={isActive == index}
        onClick={() => tabClick(index, tab)}
      />
    );
  });
  const settings = {
    lazyLoading: 'ondemand',
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 500,
    variableWidth: true,
    beforeChange: (oldIndex, newIndex) => {
      onChange(tabs[newIndex]);
      setIsActive(newIndex);
    },
  };
  const slickRef = useRef<Slider | null>(null);

  return (
    <TabContainer id="tab_container">
      {isActive !== 0 && !isTablet && (
        <ChevronLeftIcon
          onClick={() => slickRef.current.slickPrev()}
          className={`absolute left-0 top-0 z-[10] h-[20px] w-[20px] cursor-pointer text-leaf`}
        />
      )}
      {isTablet ? (
        tabItems
      ) : (
        <div className="slider_wrap">
          <Slider {...settings} ref={slickRef}>
            {tabItems}
          </Slider>
        </div>
      )}
      {isActive !== tabItems.length - 1 && !isTablet && (
        <ChevronRightIcon
          onClick={() => slickRef.current.slickNext()}
          className={`absolute right-0 top-0 z-[10] h-[20px] w-[20px] cursor-pointer text-leaf`}
        />
      )}
    </TabContainer>
  );
};
