import tw from 'tailwind-styled-components';

export const PromotionContainer = tw.div`
    block
    md:flex
    h-full
    mt-[42px]
    md:my-0
`;

export const TextContainer = tw.div`
    flex
    flex-col
    justify-center
`;

export const ImageContainer = tw.div`
    mt-[64px]
    md:mt-0
    flex
    flex-col
    justify-center
    relative
`;
