import { useDispatch, useSelector } from 'react-redux';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import SimpleImageOrVideo from 'components/molecules/image-or-video/simple-image-or-video';
import { ImageContainer, LoungeContainer } from 'components/molecules/tabs/dispensary-tabs/lounge-tab/styles';
import { ContentContainer } from 'components/molecules/tabs/dispensary-tabs/styles';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import EventsReservationModal from 'components/organisms/popups/reservation-modal/events-reservation-modal';
import MakeReservationModal from 'components/organisms/popups/reservation-modal/make-reservation-modal';
import SmokeasyModal from 'components/organisms/popups/reservation-modal/reservation-modal';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { PopUpType } from 'helpers/types';
import { getMundeleinLoungeMenuBtns } from 'helpers/utils/convert-to-dispensary-page-type';
import { tablet } from 'helpers/utils/screensizes';
import CalenderIcon from 'public/icons/figma/calender.svg';
import { setOpenLoungeModal } from 'redux/reducers/lounge-popup-slice';
import { RootState } from 'redux/store';

interface LoungeTabProps {
  data: Record<string, any>;
}

export const LoungeTab = ({ data }: LoungeTabProps) => {
  const { title, description, image, ctaCollection } = data || {};
  const dispatch = useDispatch();
  const openLoungeModalFromRedux = useSelector((state: RootState) => state.loungePopup.loungePopup);
  const handleBackClick = (e) => {
    e.preventDefault();
    dispatch(
      setOpenLoungeModal({
        smokeasy: true,
        eventCalender: false,
        reservation: false,
      }),
    );
  };
  const openLoungePopUp = (popUpType: PopUpType) => {
    dispatch(
      setOpenLoungeModal({
        eventCalender: popUpType === PopUpType.EVENT_LIST ? true : false,
        reservation: popUpType === PopUpType.RESERVATION ? true : false,
        smokeasy: popUpType === PopUpType.SMOKEY ? true : false,
      }),
    );
  };
  const handleButtonClick = (e, popUpType: PopUpType) => {
    if (!popUpType) return;
    e?.preventDefault();
    openLoungePopUp(popUpType);
  };
  const handleClose = () => {
    dispatch(
      setOpenLoungeModal({
        reservation: false,
        eventCalender: false,
        smokeasy: false,
      }),
    );
  };
  const clickHandler = (text) => {
    const type = text.toLowerCase().includes('reservation')
      ? PopUpType.RESERVATION
      : text.toLowerCase().includes('event')
      ? PopUpType.EVENT_LIST
      : null;
    openLoungePopUp(type as PopUpType);
  };
  const [isTablet] = useMediaQuery(tablet);
  const selectedImage = isTablet ? image?.desktopImage : image?.mobileImage ? image?.mobileImage : image?.desktopImage;
  if (!data) return <></>;
  return (
    <>
      <>
        {openLoungeModalFromRedux?.reservation && ctaCollection?.items?.[0] && (
          <MakeReservationModal
            reservationModalData={ctaCollection.items[0]}
            handleClose={handleClose}
            handleBackClick={handleBackClick}
          />
        )}
        {openLoungeModalFromRedux?.eventCalender && ctaCollection?.items?.[1] && (
          <EventsReservationModal
            eventCalendarModalData={ctaCollection.items[1]}
            handleClose={handleClose}
            handleBackClick={handleBackClick}
          />
        )}
        {openLoungeModalFromRedux?.smokeasy && ctaCollection?.items?.[2] && (
          <SmokeasyModal
            smokeEasyData={{
              ...ctaCollection?.items[2],
              btns: getMundeleinLoungeMenuBtns(ctaCollection),
            }}
            handleClose={handleClose}
            handleButtonClick={handleButtonClick}
          />
        )}
      </>
      <LoungeContainer>
        <ContentContainer className=" !block gap-x-[42px] rounded-none px-5 py-[56px] md:!flex md:w-[858px] md:rounded-md md:px-[30px] md:py-[44px] xl:w-[1192px] min1440:px-[90px] min1440:py-[88px]">
          <div className="text-center md:max-w-[305px] md:text-left">
            <Typography variant="h4" as={'h3'} className="my-6 !text-white">
              {title}
            </Typography>
            <Typography
              variant="body-small"
              className="mb-6 !text-grey-300 md:mb-[42px] [&_div_p]:pb-0 [&_p]:text-white"
              id="lounge_description"
            >
              <ContentfulRichText data={description} />
            </Typography>
            <CommonButton
              id="reservation_cta"
              button-type="secondary"
              color="white"
              size="sm"
              className="mb-6"
              onClick={() => clickHandler(ctaCollection?.items?.[0]?.buttonText)}
            >
              {ctaCollection?.items?.[0]?.buttonText}
            </CommonButton>
            <CommonButton
              id="events_cta"
              button-type="tertiary"
              color="white"
              size="sm"
              className="mx-auto flex !p-0 md:mx-0"
              onClick={() => clickHandler(ctaCollection?.items?.[1]?.buttonText)}
            >
              {ctaCollection.items[1].buttonText}
              <CalenderIcon className="ml-2 mt-[2px]" />
            </CommonButton>
          </div>
          {selectedImage && (
            <ImageContainer>
              <SimpleImageOrVideo
                imageOrVideo={selectedImage}
                imageProps={{
                  id: 'lounge_image',
                  objectFit: 'contain',
                }}
              />
            </ImageContainer>
          )}
        </ContentContainer>
      </LoungeContainer>
    </>
  );
};
