import tw from 'tailwind-styled-components';

export const DeliveryContainer = tw.div`
    block
    xl:flex
    h-full
    mt-[42px]
    md:my-0
    md:w-[858px]
    xl:w-[1192px]
`;

export const StepContainer = tw.div`
    flex
    items-center
`;

export const ImageContainer = tw.div`
    relative
    mr-0
    flex
    h-[44px]
    w-[20px]
    md:mr-11
    md:h-auto
    md:w-auto
`;

export const MapContainer = tw.div`
    md:max-h-[205px]
`;

export const TextContainer = tw.div<{ $display: string }>`
    ${(p) => p.$display}
    md:flex
    md:py-10
    md:px-12
    px-5
    pt-[32px]
    pb-[42px]
    text-center
    justify-center
`;

export const HoursContainer = tw.div`
    text-left
`;

export const Spacer = tw.div`
    min-w-[24px]
    max-w-[96px]
`;

export const InfoDetailsContainer = tw.div`

`;

export const InfoDetailWrapper = tw.div<{ $borderTop: boolean }>`
    flex
    py-3
    md:py-4
    ${(p) => (p.$borderTop ? 'border-t-[1px] border-t-solid border-t-kief border-opacity-60' : null)}
`;
