import React from 'react';
import { ModalCloseButton, RevealDiv, RevealOverlay } from 'components/organisms/popups/styles';

interface CommonModalProps {
  divID?: string;
  handleClose(e: any): void;
  closeButtonColor?: string;
  overlayStyle?: string;
  modalRef?: any;
  children: any;
  reservationModal?: boolean;
  noPadding?: boolean;
  customModalStyle?: string;
  noBorder?: boolean;
  ariaHidden?: boolean;
  showExit?: boolean;
  CustomExit?: JSX.Element;
}

const CommonModal = ({
  handleClose,
  divID,
  children,
  closeButtonColor,
  overlayStyle,
  modalRef,
  reservationModal,
  noPadding,
  customModalStyle,
  noBorder,
  ariaHidden,
  showExit = true,
  CustomExit,
}: CommonModalProps) => {
  return (
    <RevealOverlay onClick={handleClose} id={`reveal_overlay_${divID}`} $overlayStyle={overlayStyle as string}>
      {CustomExit}
      <RevealDiv
        id={divID}
        data-reveal
        role="dialog"
        data-events="resize"
        $reservationModal={reservationModal}
        $noPadding={noPadding}
        $customStyle={customModalStyle}
        $noBorder={noBorder}
        onClick={(e) => e.stopPropagation()}
        data-yeti-box={divID}
        data-resize={divID}
        ref={modalRef}
        aria-hidden={ariaHidden}
      >
        {children}
        {showExit && (
          <button data-close aria-label="Close modal" onClick={handleClose} id={`exit_button_${divID}`}>
            <ModalCloseButton $color={closeButtonColor} />
          </button>
        )}
      </RevealDiv>
    </RevealOverlay>
  );
};

export default CommonModal;
