import { useEffect } from 'react';
type placementProps = 'top' | 'bottom' | 'left' | 'right';
interface TooltipScrollingPositionProps {
  tooltipIconRef: any;
  tooltipContentRef: any;
  placement: placementProps;
  scrollPlacement: placementProps;
  setScrollPlacement: (placement: placementProps) => any;
  isTablet?: boolean;
}

export const useTooltipScrollingPosition = ({
  tooltipIconRef,
  tooltipContentRef,
  placement,
  scrollPlacement,
  setScrollPlacement,
}: TooltipScrollingPositionProps) => {
  useEffect(() => {
    const scrollingTooltipPostion = () => {
      const icon = tooltipIconRef?.current?.getClientRects?.()?.[0];
      const tooltipContent = tooltipContentRef?.current?.getClientRects?.()?.[0];
      if (placement === 'top' || placement === 'bottom') {
        if (typeof icon?.x === 'number' && typeof tooltipContent?.x === 'number') {
          if (icon.y > tooltipContent.y) {
            if (scrollPlacement !== 'top') setScrollPlacement('top');
          } else if (icon?.y < tooltipContent?.y) {
            if (scrollPlacement !== 'bottom') setScrollPlacement('bottom');
          }
        }
      } else if (placement === 'right' || placement === 'left') {
        if (typeof icon?.x === 'number' && typeof tooltipContent?.x === 'number') {
          if (icon.x < tooltipContent.x) {
            if (scrollPlacement !== 'right') setScrollPlacement('right');
          } else if (icon?.y > tooltipContent?.y) {
            if (scrollPlacement !== 'left') setScrollPlacement('left');
          }
        }
      }
    };
    window.addEventListener('scroll', scrollingTooltipPostion);
    return () => {
      window.removeEventListener('scroll', scrollingTooltipPostion);
    };
  }, [placement, scrollPlacement]);
};

export const setTooltipArrowPosition = ({
  tooltipIconRef,
  tooltipContentRef,
  placement,
  scrollPlacement,
  setScrollPlacement,
  isTablet,
}: TooltipScrollingPositionProps) => {
  const icon = tooltipIconRef?.current?.getClientRects?.()?.[0];
  const tooltipContent = tooltipContentRef?.current?.getClientRects?.()?.[0];
  if (placement === 'top' || placement === 'bottom') {
    if (typeof icon?.x === 'number' && typeof tooltipContent?.x === 'number') {
      if (icon.y > tooltipContent.y) {
        if (scrollPlacement !== 'top') setScrollPlacement('top');
      } else if (icon?.y < tooltipContent?.y) {
        if (scrollPlacement !== 'bottom') setScrollPlacement('bottom');
      }
    }
  } else if ((placement === 'right' || placement === 'left') && isTablet) {
    if (typeof icon?.x === 'number' && typeof tooltipContent?.x === 'number') {
      if (icon.x < tooltipContent.x) {
        if (scrollPlacement !== 'right') setScrollPlacement('right');
      } else if (icon?.y > tooltipContent?.y) {
        if (scrollPlacement !== 'left') setScrollPlacement('left');
      }
    }
  }
};
