import tw from 'tailwind-styled-components';

export const LoungeContainer = tw.div`
    block
    md:flex
    h-full
    mt-[42px]
    md:my-0
`;

export const ImageContainer = tw.div`
    mt-[64px]
    md:mt-0
    w-full
    flex
    justify-center
`;
