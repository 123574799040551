import tw from 'tailwind-styled-components';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';

export const HeaderDiv = tw.div`
    bg-[#121314]
    py-3
    px-[15px]
`;

export const HeaderTitle = tw.p`
    text-white
    font-semibold
    text-sm
    leading-[17px]
    mb-0
    uppercase
`;

export const BodyContent = tw.div`
    p-[30px]
`;

export const EventImage = tw.div`
    w-full
    h-[169px]
`;

export const LoungeImageContainer = tw.div`
    [&_img]:w-full
    [&_img]:h-full
    [&_img]:object-contain
    [&_img]:inline-block
    [&_img]:align-middle
    [&_img]:max-w-full
`;

export const EventDetailsContent = tw.p`
    text-sm
    leading-[18px]
    text-black
    font-normal
    mt-8
    mb-[52px]
`;

export const EventParagraph = tw.p`
    mb-4
    leading-[1.6]
`;

export const EventButton = tw.button`
    py-2.5
    px-5
    bg-leaf
    rounded-[5px]
    w-full
    text-left
    flex
    items-center
    justify-between
    mb-7
    cursor-pointer
`;

export const ButtonTitle = tw.span`
    inline-block
    text-white
    font-semibold
    tracking-wider
    text-base
    leading-[17px]
`;

export const AngleRight = tw(ChevronRightIcon)`
    text-white
    font-black
`;

export const PopupBack = tw.div`
    py-[19px]
    px-5
`;

export const BackLink = tw.a`
    flex
    text-sm
    leading-[17px]
    font-semibold
    text-leaf
    cursor-pointer

`;

export const AngleLeft = tw(ChevronLeftIcon)`
    font-black
    mr-2
    mt-0.5
`;

export const PopupBodyContent = tw.div`
    pt-0
    px-[30px]
    pb-4

    [&_ul]:text-base
    [&_ul]:tracking-wider
    [&_ul]:leading-[22px]
    [&_ul]:ml-6
    [&_ul]:list-disc

    [&_li]:leading-[23px]

    [&_a]:text-primary-100
`;

export const ReservationIframe = tw.iframe`
    w-full
    overflow-hidden
    h-[532px]
    border-none
`;
