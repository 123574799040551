import tw from 'tailwind-styled-components';

export const AmenityPaymentContainer = tw.div`
    block
    xl:flex
    md:my-0
    items-center
    xl:w-[1192px]
    md:w-[858px]
`;

export const Spacer = tw.div`
    min-w-[24px]
    xl:max-w-[96px]
    md:max-w-[120px]
    h-[18px]
    hidden
    md:block
`;

export const ContentContainer = tw.div`
    bg-leaf
    rounded-none
    md:rounded-r-none
    md:!rounded-b-md
    xl:!rounded-b-none
    xl:!rounded-r-md
    block
    h-full
    md:flex
    md:h-[488px]
    md:px-12
    xl:px-0
    xl:h-[559px]
    xl:px-[60px]
    px-12
    py-[42px]
    xl:w-[755px]
    justify-center
    items-center
`;

export const ImageIconClasses = `
    !min-h-[48px]
    !min-w-[48px]
`;

export const TooltipContainer = tw.div`
    ml-2
`;
